
$textMain: #000;
$textSecondary: #666666;
$grey3: #666666;
$grey2: #BDBDBD;
$grey1: #E4EBF2;
$mainButtonColor: #FF1036;
$hoverButtonColor: #EF072C;
$disabledButtonColor: #ff879b;
$redError: #c00000;
$orgBoxes: #000;

$sideMenuColor: #000;
$sideMenuItemColor: #fff;
$sideMenuSelectedTabBoxColor: #FF1036;



:root {
  --textSecondary: #{$textSecondary};
  --grey3: #{$grey3};
  --grey2: #{$grey2};
  --grey1: #{$grey1};
  --redError: #{$redError};
  --mainButtonColor: #{$mainButtonColor};
  --hoverButtonColor: #{$hoverButtonColor};
  --disabledButtonColor: #{$disabledButtonColor};
  --orgBoxes: #{$orgBoxes};
  --sideMenuColor: #{$sideMenuColor};
  --sideMenuItemColor: #{$sideMenuItemColor};
  --sideMenuSelectedTabBoxColor: #{$sideMenuSelectedTabBoxColor};
}

:export {
  textMain: $textMain;
  textSecondary: $textSecondary;
  grey3: $grey3;
  grey2: $grey2;
  grey1: $grey1;
  redError: $redError;
  mainButtonColor: $mainButtonColor;
  hoverButtonColor: $hoverButtonColor;
  disabledButtonColor: $disabledButtonColor;
  orgBoxes: $orgBoxes;
  sideMenuColor: $sideMenuColor;
  sideMenuItemColor: $sideMenuItemColor;
  sideMenuSelectedTabBoxColor: $sideMenuSelectedTabBoxColor;
}
