* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Quicksand', 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

root {
  background-color: #282c34;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px #fff inset;
  box-shadow: 0 0 0 100px #fff inset;
}

a {
  color: #000;
}

.Toastify {
  &__toast {
    color: #FFF !important;
    background: #000 !important;

    & .Toastify__close-button {
      color: #FFF !important;
      opacity: 1 !important;
    }
  }

  &__toast-container {
    width: auto !important;
  }

  &__progress-bar {
    opacity: 0 !important;
  }

  &__close-button {
    display: none;
  }
}
